import {React} from 'react';
import "./Projects.css"
import {Row,Col, Container, Button} from 'react-bootstrap';
import {Card,Carousel, Badge} from 'react-bootstrap';
import BlinkTitle from './BlinkTitle';

let fitinBadges = ["React","Firebase","Figma", "JIRA"]
let medicalBadges = ["SQL", "PHP"]
let blackjackBadges = ["Java","Android Studio", "Firebase"]
let lumosBadges = ["Python", "Discord.py", "RU Hacks 2021"] 
let techsrcBadges = ["Figma", "TO Hacks 2021"]
let cps511Badges = ["C++", "OpenGL"]
let cardealershipBadges = ["Java"]
let blogBadges = ["React", "MongoDB", "Express.js", "Node.js", "MERN Stack"]


function ProjectImage(props){
  return (
    <p>
      <a href={props.url}>
  <img
      className="d-block w-100"
      src={props.src}
      alt="First slide"
      style={{overflow: 'hidden', height: "300px", objectFit: "cover"}}
    />
    </a>
    </p>
  );
}


function BackgroundImage(props){
  return (
    <p>
      <a href={props.url}>
      <img
          className="d-block w-100"
          src={props.src}
          alt="First slide"
          
          style={{overflow: 'hidden', height: "1000px", objectFit: "cover", filter: "brightness(20%)"}}
        />
    </a>
    </p>
  );
}
const Projects = () => {
  return (
    <div>
      <BlinkTitle
      text1="<Pro"
      text2="jects>"
      />
    <Carousel data-interval="false">
      <Carousel.Item>
        <BackgroundImage src="fitin.PNG"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard">
          <Card.Header className="mt-2" as="h5">{fitinBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
          <ProjectImage src="fitin.PNG" url="http://fitin.web.app"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>FitIn</b></span></h1></Card.Title>
            <Card.Text>
            FitIn is a social media application catered to fitness enthusiasts. This platform enables users to connect with other fitness enthusiasts to share workout plans, goals, and progress.
            </Card.Text>
            <Container>
              <Row>
                <Col md={{span:3, offset: 3}}>
                <Button as="a" href="http://fitin.web.app" variant="primary" className="alignButton mt-3">Website</Button>
                </Col>
                <Col md={{span:3, offset: 0}}>
                  <Button  as="a" href="https://github.com/seyon123/cps714-g6" variant="primary" className="alignButton mt-3">GitHub</Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="blog.PNG"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard">
          <Card.Header className="mt-2" as="h5">{blogBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
          <ProjectImage src="blog.PNG" url="https://github.com/raj-mistry/react-mongodb"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Productivity Dashboard</b></span></h1></Card.Title>
            <Card.Text>
            Implemented a Blog website within a few weeks using MongoDB and Express to learn the MERN Stack. Implemented Authentication with JWT and Encryption. Currently adding productivity features.
            </Card.Text>
            <Button as="a" href="https://github.com/raj-mistry/react-mongodb" variant="primary" className="alignButton">GitHub</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="medicaldbms.PNG"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard">
          <Card.Header className="mt-2" as="h5">{medicalBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
            <ProjectImage src="medicaldbms.PNG" url="https://github.com/raj-mistry/Medical-Clinic-Database-Management-System"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Medical Database Management System</b></span></h1></Card.Title>
            <Card.Text>
              Developed an ER model, normalized the model, and implemented tables in Oracle SQL for a Medical Relational Database Management System. Created a user interface for this database using PHP.
            </Card.Text>
            <Button as="a" href="https://github.com/raj-mistry/Medical-Clinic-Database-Management-System" variant="primary" className="alignButton">GitHub</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <BackgroundImage src="blackjack.png"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard" >
          <Card.Header className="mt-2" as="h5">{blackjackBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
            <ProjectImage src="blackjack.png" url="https://github.com/raj-mistry/blackjack"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Black Jack Android</b></span></h1></Card.Title>
            <Card.Text>
              A mobile application created for Android devices to play Black Jack. This application allows users to play against an AI dealer, and share their wins and losses with other players.
            </Card.Text>
            <Button as="a" href="https://github.com/raj-mistry/blackjack" variant="primary" className="alignButton">GitHub</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="cardealership.jpg"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard" >
          <Card.Header className="mt-2" as="h5">{cardealershipBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Car Dealership Simulator</b></span></h1></Card.Title>
            <Card.Text>
              A console-based Java application that lets employees of a car dealership to sort and filter vehicles at a dealership. This application also keeps track of vehicles sold, and current vehicle availability in the dealership's inventory.
            </Card.Text>
            <Button as="a" href="https://github.com/raj-mistry/cardealershipsimulator" variant="primary" className="alignButton">GitHub</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="lumos.gif"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard" >
          <Card.Header className="mt-2" as="h5">{lumosBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
            <ProjectImage src="lumos.gif" url="https://devpost.com/software/lumos-vpdg90"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Lumos</b></span></h1></Card.Title>
            <Card.Text>
              A Discord bot created for RU Hacks 2021. This bot lets users parse their resume to determine if it is ATS safe, find grammatical errors, and generate coverletters based on a template.
            </Card.Text>
            <Container>
              <Row>
                <Col md={{span:3, offset: 3}}>
                <Button as="a" href="https://devpost.com/software/lumos-vpdg90" variant="primary" className="alignButton mt-3">Website</Button>
                </Col>
                <Col md={{span:3, offset: 0}}>
                  <Button  as="a" href="https://github.com/akr13/lumos" variant="primary" className="alignButton mt-3">GitHub</Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="techsrc.png"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard" >
          <Card.Header className="mt-2" as="h5">{techsrcBadges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
          <ProjectImage src="techsrc.png" url="https://devpost.com/software/techsrc"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>Tech Src</b></span></h1></Card.Title>
            <Card.Text>
              A mobile app concept for Toronto Hacks 2021. TechSrc provides a sustainable solution that allows users to safely dispose of their devices at local electronic vendors, so that the components can be properly recycled. Wireframe developed using Figma.
            </Card.Text>
                <Button as="a" href="https://devpost.com/software/techsrc" variant="primary" className="alignButton mt-3">Website</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <BackgroundImage src="CPS511.PNG"/>
        <Carousel.Caption>
        <Card className="bg-dark text-white carouselCard" >
          <Card.Header className="mt-2" as="h5">{cps511Badges.map((badge)=> {return(<Badge style={{marginLeft: "20px"}} bg="secondary">{badge}</Badge>)})}
          </Card.Header>
          <Card.Body>
          <ProjectImage src="CPS511.PNG" url="https://www.youtube.com/watch?v=uyGn77KCHkY"/>
            <Card.Title className="mb-4"><h1><span className='highlight'><b>OpenGL 3D Game</b></span></h1></Card.Title>
            
            <Card.Text>
              A 3D round-based third person shooter Computer Graphics project developed using OpenGL and C++. Features an animated and pose-able robot modelled using Primitive OpenGL objects. 
            </Card.Text>
                <Button as="a" href="https://www.youtube.com/watch?v=uyGn77KCHkY" variant="primary" className="alignButton mt-3">Website</Button>
          </Card.Body>
        </Card>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
};

export default Projects;