import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SiLinkedin, SiDevpost } from "react-icons/si";
import {  AiTwotoneMail } from "react-icons/ai";
import { BsGithub } from "react-icons/bs";



const Footer = () => {
  return (
    <footer>
      <Container >
        <Row> 
          <Col md={{span:4, offset: 0}}>
          <Row>
            <a style={{textAlign: "center"}} href="https://www.linkedin.com/in/rvmistry/">
            <SiLinkedin className="mt-5" style={{color: "white"}} size={50}/>
            </a>
          </Row>
          </Col>
          <Col md={{span:4, offset: 0}}>
          <Row>
          <a style={{textAlign: "center"}} href="https://github.com/raj-mistry">
            <BsGithub className="mt-5" style={{color: "white"}} size={50}/>
            </a>
          </Row>
          </Col>
          <Col md={{span:4, offset: 0}}>
          <Row>
          <a style={{textAlign: "center"}} href="https://devpost.com/raj-mistry">
            <SiDevpost className="mt-5 mb-5" style={{color: "white"}} size={50}/>
            </a>
          </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;