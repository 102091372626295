import React from "react";
import {Container, Navbar,Row, Col} from "react-bootstrap";
import "./NavBar.css"

function NavigationBar() {
  return (
    <>
      <Navbar className="bar" variant="dark">
        <Container fluid className="justify-content-end">
            <Row>
                <Col cols="auto">
                <Navbar.Brand href="/" className="urlText">
                    Home
                </Navbar.Brand>
                </Col>
                {/* <Col cols="auto">
                <Navbar.Brand href="/" className="urlText">
                    Contact
                </Navbar.Brand>
                </Col> */}
                <Col className="float-right"cols="auto">
                <Navbar.Brand href={require('../assets/resume.pdf')} className="urlText">
                    Resume
                </Navbar.Brand>
                </Col>
            </Row>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;