import {React} from "react";
import NavigationBar from "../components/NavBar";
import "./LandingPage.css"
import { Container} from "react-bootstrap";
import BlinkTitle from "../components/BlinkTitle";
import Introduction from "../components/Introduction";
import Projects from "../components/Projects";
import Footer from "../components/Footer";

function LandingPage(){
    return (
        <div>
            <NavigationBar/>
            <Container fluid>
                <Introduction/>
                
                <Projects/>
            </Container>
            <Footer/>
        </div>
    )
}

export default LandingPage;