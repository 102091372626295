import {React,useState,useEffect} from 'react';
import { Table } from 'react-bootstrap';
import "./BlinkTitle.css"

let interval;
const BlinkTitle = (props) => {
    const [blink, setBlink] = useState(true);

    useEffect(() => {
        interval = setInterval(() => {
          setBlink(state=> !state);
        }, 1000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }, [])

    return (

        <span style={{display: "table", margin: "0 auto"}}>
        <h1 className="title" style={{display: "inline-block", color: "white", marginLeft: "20px"}}>{props.text1}</h1>
        <h1 className="titleColor title"style={{display: "inline-block"}}>{props.text2}</h1>
        {blink ? <h1 className="title" style={{display: "inline-block", color: "white"}}>__</h1>: <h1 className="title" style={{display: "inline-block", color: "#266bff"}}>__</h1>}
        </span>
    );
  };
  
  export default BlinkTitle;