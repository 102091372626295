import {React} from 'react';
import "./Introduction.css"
import {Row,Col, Container, Button} from 'react-bootstrap';

const Introduction = () => {
  return (
    <div>
    <div className="landing-page">
      <Container style={{height: "100%"}}>
        <Row>
          <Col className="center"cols={6}>
                <h1 className="mt-5">Hi, I'm Raj, a <span className='highlight'><b>Software Engineer</b><Button href={require('../assets/resume.pdf')} variant="primary" className="alignButton m-3">Resume</Button></span></h1>
                <p style={{marginTop: "50px"}}>
                </p>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
};

export default Introduction;