import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route
            path="/"
            element={<LandingPage/>}
        />
      <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
